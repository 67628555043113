import lazySizes from "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit.js";
import "lazysizes/plugins/blur-up/ls.blur-up.js";

// styles
import "./index.scss";

// polyfills
import "lazysizes/plugins/respimg/ls.respimg.js";

if (!("object-fit" in document.createElement("a").style)) {
    require("lazysizes/plugins/object-fit/ls.object-fit.js");
}

// Config

lazySizes.cfg.blurupMode = "auto";
lazySizes.cfg.blurUpClass = "lazy-box__placeholder";
lazySizes.cfg.blurUpInviewClass = "lazy-box__placeholder--visible";
lazySizes.cfg.blurUpLoadingClass = "lazy-box__placeholder--loading";
lazySizes.cfg.blurUpLoadedClass = "lazy-box__placeholder--loaded";
lazySizes.cfg.blurUpLoadedOriginalClass = "lazy-box__placeholder--element-loaded";
